<template>
  <canvas ref="canvas" />
</template>

<script>
import QRCode from 'qrcode'

export default {
  mounted () {
    QRCode.toCanvas(this.$refs.canvas, this.text, { width: this.width }, (err) => { console.log(err) })
  },
  props: {
    text: {
      type: String,
      required: true
    },
    width: Number
  }
}
</script>
